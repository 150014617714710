/* colours */
$c-bg: #E8F0F2;
$c-text: #707070;
$c-text-light: lighten($c-text, 20%);
$c-border: darken(white, 5%);
$c-border-dark: lighten($c-text, 52%);
$c-primary: #39A2DB;
$c-dark: #053742;

//#053742
//#39A2DB
//#A2DBFA
//#E8F0F2

// social media
$c-facebook: #3b5998;
$c-twitter: #55acee;
$c-instagram: #405de6;
$c-youtube: #cd201f;
$c-pinterest: #bd081c;
$c-linkedin: #0077b5;
$c-microsoft: #D73A17;

$c-red: #d95565;
$c-green: #63B76C;
$c-blue: #39A2DB;
$c-darkblue: #3A4488;
$c-orange: #F0AA61;
$c-tint: #FBFBFF;
$c-purple: #6363AC;
$c-navyteal: #1f6d8f;
$c-darkpurple: #556CB6;
