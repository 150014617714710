@import 'components/global.scss';

.logo {

  display: block;
  color: #fff;
  font-weight: 500;
  width: 1.3em;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  font-size: 200%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);

    animation: blinkTextCursor 1000ms steps(44) infinite normal;

}

.logo::before {
  content: "d>"
}

@keyframes blinkTextCursor {
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
}