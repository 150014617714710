@import 'components/global.scss';

.nav {

  height: $scale8;
  background-color: $c-navyteal;
  background-image: radial-gradient(ellipse at top, #39A2DB, #176d80);


  > div {

    height: 100%;

  }

  nav {

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    a {

      color: white;
      margin-right: $scale;
      text-decoration: none;

      &:last-child {

        margin-right: 0;

      }
    }
  }
}

.logo {

  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  transform: translateY(-50%);

  animation: typing 1s steps(4) 4s 1 normal both,
  blinkTextCursor 1000ms steps(44) infinite normal !important;

}
@keyframes typing {
  from{width: 1.25em;}
  to{width: 3.40em;}
}
@keyframes blinkTextCursor {
  from{border-right-color: rgba(255,255,255,.75);}
  to{border-right-color: transparent;}
}

.transparent {

  background-image: none;
  background-color: transparent;

}
