@import 'components/global.scss';

.button {

  position: fixed;
  left: $scale;
  bottom: $scale;
  width: $scale5;
  height: $scale5;
  z-index: 9;
  cursor: pointer;
  text-align: center;
  line-height: $scale5;
  border-radius: 100%;
  background-color: $c-red;

  svg {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

.feedback {

  position: fixed;
  right: $scale;
  bottom: $scale;
  left: $scale;
  background-color: $c-dark;

  header {

    color: white;
    background-color: transparent;
    border-bottom: 1px dotted lighten($c-dark, 10%);

  }

  form {

    margin-top: $scale2;

    textarea + svg {

      display: none;

    }
  }

  @media (min-width: $mobile){

    left: auto;
    width: $scale14;

  } 
}

.rating {

  text-align: center;

  button {

    margin-right: $scale;
    width: $scale5;
    height: $scale5;
    transition: 0.15s ease-in-out all;

    &:hover {

      transform: scale(1.2);

    }

    &:last-child {

      margin-right: 0;

    }
  }
}

.close {

  position: absolute;
  top: $scale;
  right: $scale;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;

  &:hover {

    opacity: 1;

  }
}